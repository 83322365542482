var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f1 apps-page w-full dF fC f1 px-4"},[_vm._m(0),_c('a-card',{staticClass:"my-3",attrs:{"bodyStyle":{ padding: 0 }}},[_c('div',[_c('a-table',{attrs:{"rowKey":(e) => e.key,"columns":_vm.columns,"dataSource":_vm.partnerDeals,"loading":_vm.loading,"pagination":{
                        current: _vm.currentPage,
                        total: _vm.totalPartners,
                        pageSize: _vm.pageSize,
                    }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"company",fn:function(partnerDeal){return _c('div',{},[_vm._v(" "+_vm._s(partnerDeal.company)+" ")])}},{key:"companyAddress",fn:function(partnerDeal){return _c('div',{},[_vm._v(" "+_vm._s(partnerDeal.companyAddress)+" ")])}},{key:"companyWebsite",fn:function(partnerDeal){return _c('div',{},[_vm._v(" "+_vm._s(partnerDeal.companyWebsite)+" ")])}},{key:"registrationExpiry",fn:function(partnerDeal){return _c('div',{},[_vm._v(" "+_vm._s(_vm.calculateExpiry(partnerDeal.registrationExpiry))+" ")])}},{key:"partner",fn:function(partnerDeal){return _c('div',{},[_vm._v(" "+_vm._s(partnerDeal.partner.companyName)+" ")])}},{key:"user",fn:function(partnerDeal){return _c('div',{staticClass:"dF aC",staticStyle:{"justify-content":"center"}},[_c('a-tooltip',{attrs:{"overlayClassName":"change-tooltip-color"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"dF aC",staticStyle:{"gap":"10px"}},[_c('div',[_vm._v(" "+_vm._s(partnerDeal.user.firstName)+" "+_vm._s(partnerDeal.user.lastName)+" ")]),_c('div',[_vm._v(" ("+_vm._s(partnerDeal.user.email)+") ")])])]),_c('a-avatar',{key:partnerDeal.user.id,class:_vm.$style.avatar,style:({
									'background-color':
										partnerDeal.user.id === 'id'
											? '#9693e7'
											: 'var(--primary)',
								}),attrs:{"shape":"circle","size":"default"}},[_vm._v(_vm._s(partnerDeal.user.firstName ? partnerDeal.user.id !== "id" ? partnerDeal.user.firstName[0].toUpperCase() : partnerDeal.user.firstName : "--"))])],2)],1)}},{key:"createdAt",fn:function(partnerDeal){return _c('div',{staticStyle:{"color":"var(--med-gray)"}},[_vm._v(" "+_vm._s(_vm.getDate(partnerDeal.createdAt))+" ")])}},{key:"actions",fn:function(partnerDeal){return _c('div',{staticClass:"dF aC",staticStyle:{"gap":"20px"}},[_c('div',{staticClass:"popoverContent",on:{"click":function($event){_vm.selectedPartnerDeal = partnerDeal;_vm.deleteModal = true;}}},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"type":"delete"}})],1)])}}])})],1)]),_c('a-modal',{attrs:{"title":"Delete Deal","ok-text":"Delete","centered":true},on:{"ok":_vm.deletePartnerDeal},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('p',[_vm._v(" Are you sure you want to delete this Deal "),_c('strong',[_vm._v(" "+_vm._s(_vm.selectedPartnerDeal.company)+" ")]),_vm._v(" ? ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dF aC"},[_c('div',{staticClass:"dF aC f1"},[_c('h3',[_vm._v("Partner Deals")])])])
}]

export { render, staticRenderFns }